@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: white) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* App and Homepage */
body {
  color: rgb(var(--foreground-rgb));
  background: aliceblue
}
.app-title {
  margin-top: 1rem;
  font-size: 2.5rem;
}

img {
  display: block;
  margin: 0 auto;
}

.video-container {
  width: 55rem;
  height: auto;
  margin: 0 auto;
}

.carousel-container {
  margin-top: 10rem;
  margin: 0 auto;
}

.weather-dashboard-card {
  margin-right: 5rem;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.weather-dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  flex-wrap: wrap;
}

.search-container {
  /* margin: 1rem; */
}

.search-input {
  margin-right: 1rem;
  border: 1px solid black
}

.search-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.current-weather-container {
  /* margin-left: 1rem; */
  margin: 1rem auto;
  border-radius: 0.25rem;
  padding: 1rem;
}

.current-weather-details {
  background: linear-gradient(to bottom, #83a4d4, #b6fbff);
  display: flex;
  align-items: center;
  border: 1px solid black;
  width: 22rem;
}

.current-weather-details img {
  width: 3rem;
  height: 3rem;
  margin-right: 3rem;
}

.current-weather-details p {
  margin: 0;
}

.forecast-container {
  /* margin-left: 5rem; */
  margin: 1rem auto;
  border-radius: 0.25rem;
  padding: 1rem;
}

.forecast-list {
  list-style-type: none;
  padding: 1;
  flex-direction: row;
  display: flex;
}

.forecast-item {
  background: linear-gradient(to bottom, #f2fcfe, #c4e0e5);
  /* margin-bottom: 0.5rem; */
  border: 1px solid black;
  margin: 1rem;
  padding: 1rem;
}

.forecast-details {
  display: flex;
  align-items: center;
}

.forecast-details img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.forecast-details p {
  margin-right: 1rem;
}

.image-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.image-container {
  position: relative;
  width: 20rem;
  height: 20rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
  margin-top: 5rem;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 1rem;
  text-align: center;
}

/* Navbar */
.nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin-top: 7rem;
  flex-direction: row;
}

.nav-item {
  flex: 1 1 4rem;
  height: 10rem;
  padding: .8rem;
  margin: 0.5rem;
}

.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 100%;
  border: 2px solid black;
  text-decoration: none;
  background-color: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.nav-link:hover {
  background-color: #f9f9f9;
  transform: translateY(-0.5rem);
}

.nav-title {
  font-size: 1.5rem;
  margin-bottom: 1.3rem;
}

.nav-subtitle {
  font-size: 1rem;
}

.menu-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: black; 
  color: aliceblue;
}

.menu-items li {
  margin: 0 3px;
}

.menu-items button {
  color: aliceblue;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}

.menu-items a {
  color: aliceblue;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  margin-left: 0.5rem;
  padding-right: 3rem;
}

.alert {
  background-color: #ff6666;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert button {
  background-color: white;
  border: none;
  color: #ff6666;
  font-weight: bold;
  cursor: pointer;
}

/* About  */
.about-us {
  font-size: 5rem; /* or any other desired font size */
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  margin-top: 22rem;
  margin-left: 20rem;
  position: absolute;
  flex-direction: column;
   /* or any other desired height */
}

.about-us-heading {
  font-size: 4rem; /* or any other desired font size */
  margin-bottom: 3rem; /* or any other desired margin */
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-us p {
  font-size: 1.2rem; /* or any other desired font size */
  text-align: center;
  margin: 0 2rem 08rem 6rem; /* or any other desired margin */
  display: flex;
  width: 40rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-us-img {
  margin-top: 29rem;
  margin-bottom: 2rem;
}

/* Signup */
.sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 600px;
  margin: 3rem auto;
  border: 5px solid black;
  padding: 1rem;
  font-size: 2rem;
}
.go-to-login {
  display: flex;
  margin-bottom: 25rem;
  font-size: 1.5rem;
}

.sign-up-heading {
  margin-bottom: 42rem;
  padding-left: 2rem;
  display: flex;
}

/* .submit-button {
  background-color: aqua;
  border: 3px solid black;
  width: 7rem;
  margin-top: 1rem;
} */

/* Login */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 600px;
  margin: 3rem auto;
  border: 5px solid black;
  padding: 1rem;
  font-size: 2rem;
}
.go-to-signup {
  display: flex;
  margin-bottom: 25rem;
  font-size: 1.5rem;
}

.login-heading {
  margin-bottom: 42rem;
  padding-left: 2rem;
  display: flex;
}

/* Blog card container */


.blogs-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  padding: 50px;
  background-color: none;
}

.card-container {
  display: flex;
  /* flex-direction: row; */
  width: 23rem;
  /* border: 2px solid black; */
  border-radius: 10px;
  overflow: hidden;
  padding: 1rem;
}

.blog-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px;
}

.blog-card p {
  font-size: 14px;
  line-height: 1.5;
  margin: 10px;
}

.blog-card-button:hover {
  background-color: lightblue;
}


@media only screen and (min-width: 768px) {
  .blog-card {
    width: 350px;
    height: 400px;
  }
}

/* Explore */

.explore {
  margin-left: 41rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  font-size: 4rem;
}

#map {
  margin-left: 10rem;
  margin-bottom: 5rem;
}

/* .search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 2rem;
} */

/* .map-search-box {
  margin-bottom: 1.5rem;
  margin-left: 7rem;
  margin-right: 1rem;
  margin-top: -5rem;
  font-size: 1.2rem;
  border: 2px solid black;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
} */

/* .map-search-button {
  margin-left: 8rem;
  margin-bottom: 3rem;
  border: 2px solid black;
  font-size: 1.2rem;
  width: 10rem;
  background-color: black;
  color: white
} */

.mapboxgl-control-container {
  position: relative;
  background-color: white;
}

/* .mapboxgl-ctrl .mapboxgl-ctrl-geocoder .suggestions {
  background-color: white;
  border: 1px solid #cccccc;
  border-top: none;
} */

.interest-section {
  margin: 2rem 0;
  text-align: center;
}

.interest-cards-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: 20rem;
  height: 5rem;
  margin-left: 9rem;
  margin-top: 2rem;
  margin-bottom: 80rem;
  position: relative;
}

.interest-card-text-container {
  border: 2px solid black;
  padding: 10px;
}

/* Profile */
.welcome-heading {
  font-size: 2.5rem;
  margin-left: 1.5rem;
}

.brand-name-profile {
  float: right;
  margin-right: 4rem;
}

.profile-menu-container {
  margin-top: 4rem;
  margin-left: 2rem;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.profile-menu-items {
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  flex-direction: column;
  float: left;
  display: flex;
  width: 15rem;
  margin-left: 2rem;
}
.planner-item {
  float: left;
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  border: 3px solid black;
  border-radius: 2rem;
  flex-direction: column;
}

.journal {
  /* float: left; */
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  border: 3px solid black;
  border-radius: 2rem;
  /* flex-direction: column; */
}

.photos {
  /* float: left; */
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  border: 3px solid black;
  border-radius: 2rem;
  /* flex-direction: column; */
}

.budget {
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  border: 3px solid black;
  border-radius: 2rem;
  /* flex-direction: column; */
}

.logout {
  transition: background-color 0.2s ease, transform 0.2s ease;
  font-size: 1.5rem; 
  border: 3px solid black;
  border-radius: 2rem;
}

.profile-picture-container {
  float: right;
  margin-top: 7rem;
  margin-right: 4rem;
  margin-bottom: 35rem;
}

.upload-photo-button {
  border: 1px solid black;
}

/* planner */

.planner-item {
  margin: 1.5rem;
}

.journal {
  margin: 1.5rem;
}

.download-photo {
  margin: 1.5rem;
}

.photos {
  margin: 1.5rem;
}

.budget {
  margin: 1.5rem;
}

.logout {
  margin: 1.5rem;
}

.blogpost-container {
  float: left;
  margin-left: 2rem;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  /* width: 2rem; */
}

.blog-label {
  font-size: 1.8rem;
  
}

.blogpost-textarea {
  width: 36rem;
  height: 5rem;
  margin-top: 2rem;
  border: 1px solid black;
  font-size: 1.2rem;
}

.blogpost-button {
  border: 1px solid black;
  background-color: #ccc;
  margin-left: 8rem;
  width: 8rem;
}

.planner-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.brand-name {
  margin: 0;
}

.planner-heading {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-top: 40px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-top: 20px;
}

input[type="text"],
input[type="date"],
textarea {
  border: 2px solid #ddd;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}

button[type="submit"] {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #555;
}

/* Journal */

.brand-name-journal {
  float: right;
  margin-right: 4rem;
}

.journal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.journal-entry {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.journal-entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #333;
}

button {
  background-color: #0077cc;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #005fa3;
}

input,
textarea {
  font-size: 1.2rem;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #555;
}

p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  line-height: 1.5;
}

/* Photos */

.brand-name-photos {
  float: right;
  margin-right: 4rem;
}

.photo-container {
  display: flex;
  justify-content: center; /* Align the photo grid to the center of the container */
  flex-wrap: wrap;
}

.photo-grid {
  display: inline-block;
  margin-right: 20px; /* Add space between each photo */
  padding: 10px; /* Add some padding around each photo */
}

.photo-grid:last-child {
  margin-right: 0; /* Remove margin from the last photo to prevent a gap */
}

.photo-grid img {
  width: 40%; /* Set the width of each photo to 100% to fit within their containers */
  height: auto; /* Automatically adjust the height of each photo based on their aspect ratio */
  object-fit: cover; /* Resize each photo to cover their containers */
}

.footer-container {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 130px;
}

.footer-contact {
  font-size: 1.2rem;
  text-align: center;
}

.footer-legal {
  font-size: 0.8rem;
  text-align: center;
}

.footer-legal a {
  color: white;
  text-decoration: none;
}

.footer-legal a:hover {
  text-decoration: underline;
}